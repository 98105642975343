import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';
import {
  Service,
  formatWeight,
  decodeStorageData,
  calculate,
  suspense,
  shipmentTimeByMultiple,
} from 'utils';

const ez1Token = Cookies.get('ez1');

// 回傳欄位整理
const formatListData = (payload) => {
  // 所有品項
  const resData = payload.orderData.map((obj) => ({
    orderId: obj.orderId,
    orderName: obj.orderName,
    status: +obj?.deliveryStatus?.status,
    channel: payload?.channel ?? 'all',
    img: obj.img,
    orderDescription: obj.orderDescription,
    orderTime: obj.orderTime,
    payTime: obj.credit.payTime,
    tradeTime: obj.credit.tradeTime,
    updateTime: obj?.deliveryStatus?.updateTime ?? null,
    orderSummaryPrice: +obj.orderSummaryPrice,
    orderMoney: +obj?.orderMoney,
    quantity: obj.qty,
    fastDelivery: obj?.fastDelivery?.includes('fast') ?? false,
    leadTimeWithSupplier: shipmentTimeByMultiple(obj.prodDeliverList),
    leadTimeWithoutSupplier: shipmentTimeByMultiple(
      obj.prodDeliverList,
      'pure'
    ),
    deliveryInfo: {
      deliveryName: obj?.deliveryInfo?.deliveryName ?? '',
      deliveryNo: obj?.deliveryInfo?.deliveryNo
        ? obj.deliveryInfo.deliveryNo.replace(/\W/g, '')
        : '',
    },
  }));

  return {
    list: resData,
    totalPages: Math.ceil(payload.pageOfSum),
    totalCount: payload.recordOfSum,
  };
};

const formatDetailData = (payload) => {
  let priceOrigin = 0;
  let priceSale = 0;
  let totalWeight = 0;

  const items = payload.list.reduce((acc, item) => {
    acc.push({
      productName: item.productName,
      productUrl: item.productUrl,
      productImageUrl: item.productImageUrl,
      productItemName: item?.productModel
        ? [{ optionName: item.productModel }]
        : [],
      listPrice: +item.originalPrice,
      salePrice: +item.unitPrice,
      productWeight: +item.weight,
      quantity: +item.buyQty,
      isConverted: true,
      specialTagId: +item.specialTagId,
      supplierKey: item.supplierKey,
      leadTime: item.leadTime,
    });

    priceOrigin += calculate(+item.originalPrice * +item.buyQty);
    priceSale += calculate(+item.unitPrice * +item.buyQty);
    totalWeight += calculate(+item.weight * +item.buyQty);

    return acc;
  }, []);

  const resData = {
    orderId: payload.orderNo,
    orderName: payload.orderName,
    orderDescription: payload.orderDescription,
    orderStatus: +payload.expressDelivery.deliveryStatus.status,
    orderTime: payload.orderTime,
    shipmentMethod: +payload.shipmentMethod,
    channel: payload?.channel ?? 'all',
    tradeStatus: {
      orderName: payload.orderName,
      orderTime: payload.orderTime,
      payTime: payload?.credit?.payTime ?? null,
      tradeTime: payload?.credit?.tradeTime ?? null,
      updateTime: payload?.expressDelivery?.deliveryStatus?.updateTime ?? null,
      refund: payload?.credit?.refund ?? null,
    },
    dimension: payload?.dimension ?? {
      weight: formatWeight(totalWeight),
      width: 0,
      length: 0,
      height: 0,
      size: 0,
    },
    leadTimeWithSupplier: shipmentTimeByMultiple(items),
    leadTimeWithoutSupplier: shipmentTimeByMultiple(items, 'pure'),
    total: {
      priceOrigin,
      priceSale,
      spread: calculate(priceOrigin - priceSale),
      weight: formatWeight(totalWeight),
    },
    charge: payload.charge,
    discount: payload?.discount?.reduce((acc, { name, price }) => {
      acc.push({
        name: name.replaceAll(/(【|】)/g, ''),
        fee: +price,
      });
      return acc;
    }, []),
    delivery: {
      receiverId: +payload.expressDelivery.receiverId,
      deliveryTime: payload.expressDelivery?.time ?? '0',
      transport: +payload.expressDelivery?.methodId,
      methodName: payload.expressDelivery.methodName,
      methodId: payload.expressDelivery.methodId,
      deliveryInfo: {
        // TODO: 之後要再調整並統一業務邏輯(國內配送 type)
        deliveryName: payload.expressDelivery?.deliverMethod ?? '',
        deliveryNo: payload.expressDelivery?.deliveryInfo?.deliverNo
          ? payload.expressDelivery.deliveryInfo.deliverNo.replace(/\W/g, '')
          : '',
      },
      receiver: {
        id: +payload.expressDelivery.receiverId,
        consignee: {
          identificationNumber: payload.expressDelivery?.receiverInfo?.id,
          firstName: payload.expressDelivery?.receiverInfo?.name,
          mobileNumber: payload.expressDelivery?.receiverInfo?.mobile,
        },
        deliveryAddress: {
          address: payload.expressDelivery?.receiverInfo?.address,
          memo: payload.expressDelivery?.receiverInfo?.memo,
        },
      },
      store: {
        storeNo: payload?.expressDelivery?.storeNo ?? '',
        storeName: payload?.expressDelivery?.storeName ?? '',
        address: payload?.expressDelivery?.storeAddress ?? '',
      },
    },
    summary: payload.orderSummaryPriceContainFee,
    items,
  };
  return resData;
};

// FIXME: 可再調整結構
// 訂單列表: 下拉用query
const init = {
  list: [],
  totalPages: 0,
  totalCount: 0,
};

export const orderListAPI = createApi({
  reducerPath: 'orderQuery',
  endpoints: (builder) => ({
    orderList: builder.query({
      queryFn: async (reqData) => {
        if (reqData?.searchType) {
          try {
            const resData = await Service.orderSearch({
              ...reqData,
              ez1: ez1Token,
              status: 'ordersearchlist',
              platform: 'familyMart',
            });

            if (resData?.status !== 'OK') {
              return { data: init, error: '取得訂單列表失敗' };
            }

            return { data: formatListData(resData) };
          } catch (error) {
            return { data: init, error: error };
          }
        } else {
          return { data: init };
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems) => {
        currentCache?.list.push(...newItems.list);
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useOrderListQuery } = orderListAPI;
export const resetOrderList = orderListAPI.util.resetApiState();

// 訂單明細
export const fetchOrderDetail = createAsyncThunk(
  'order/fetchOrderDetail',
  async (reqData, { rejectWithValue }) => {
    const resData = await Service.orderSearch({
      ...reqData,
      ez1: ez1Token,
      status: 'orderdetail',
      platform: 'familyMart',
    });

    if (resData?.status !== 'OK') {
      return rejectWithValue('取得訂單細節失敗');
    }

    return formatDetailData(resData.orderData);
  }
);

// 取消訂單
export const orderCancel = createAsyncThunk(
  'order/orderCancel',
  async (reqData) => {
    let resData = {};
    let retry = 0;

    do {
      resData = await Service.orderCancel(reqData);

      if (resData.code === -5001) {
        await suspense(500);
        retry += 1;

        // 平均 0.5 秒重打，超過 5 秒就停掉(約 10 次)
        if (retry >= 10) break;
      }
    } while (resData.code === -5001);

    return resData;
  }
);

// TODO: 應更名為修改訂單，因收件地址與全家門市都允許編輯
// 更新訂單配送地址
export const updateOrderDeliver = createAsyncThunk(
  'order/updateOrderDeliver',
  async (reqData, { getState, rejectWithValue }) => {
    const state = getState();
    const selectedReceiver = state.receiver.detail;

    /* 解密後的資料 */
    const { consignee, deliveryAddress } = selectedReceiver;
    let formatAddress = deliveryAddress.address;
    /* remove city and district */
    formatAddress.replace(/(^.{0,2}[市|縣])/g, '');
    formatAddress.replace(deliveryAddress.district, '');

    const resData = await Service.orderSearch({
      ...reqData,
      status: 'updatedeliver',
      platform: 'familyMart',
      address: `[${deliveryAddress.zipCode}]${deliveryAddress.city}${deliveryAddress.district}${formatAddress}`,
      name: consignee.firstName,
      mobile: consignee.mobileNumber,
      ez1: ez1Token,
    });

    if (resData?.hasError) {
      return rejectWithValue('儲存失敗');
    }

    if (resData?.status !== 'OK') {
      return rejectWithValue(resData.msg);
    }

    return '編輯成功';
  }
);

// 下載海外收據
export const downloadReceipt = createAsyncThunk(
  'order/downloadReceipt',
  async (_, { getState, rejectWithValue }) => {
    const { order } = getState();
    const { detail } = order;
    const resData = await Service.downloadReceipt({
      ez1: ez1Token,
      status: 'getinvoice',
      platform: 'familyMart',
      orderNo: detail.orderId,
    });

    if (resData?.hasError) {
      return rejectWithValue('失敗');
    }

    if (resData?.status !== 'OK') {
      return rejectWithValue(resData.msg);
    }

    return resData.picture_link;
  }
);

const initialState = {
  detail: {},
  detailError: '',
  detailLoading: null,
  status: '-1',
  channel: 'drug',
  editLoading: null,
  editResult: '',
  cancelLoading: false, // for loading mask
  receiptLoading: false,
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetOrderData: () => initialState,
    setStatus: (state, { payload }) => {
      state.status = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderDetail.pending, (state) => {
        state.detailLoading = true;
        state.detailError = '';
      })
      .addCase(fetchOrderDetail.fulfilled, (state, { payload }) => {
        state.detail = payload;
        state.detailLoading = false;
      })
      .addCase(fetchOrderDetail.rejected, (state, { payload }) => {
        state.detailLoading = false;
        state.detailError = payload;
      })
      .addCase(orderCancel.pending, (state) => {
        state.cancelLoading = true;
      })
      .addCase(orderCancel.fulfilled, (state) => {
        state.cancelLoading = false;
      })
      .addCase(orderCancel.rejected, (state) => {
        state.cancelLoading = false;
      })
      .addCase(updateOrderDeliver.pending, (state) => {
        state.editLoading = true;
        state.editResult = '';
      })
      .addCase(updateOrderDeliver.fulfilled, (state, { payload }) => {
        state.editResult = payload;
        state.editLoading = false;
      })
      .addCase(updateOrderDeliver.rejected, (state, { payload }) => {
        state.editResult = payload;
        state.editLoading = false;
      })
      .addCase(downloadReceipt.pending, (state) => {
        state.receiptLoading = true;
      })
      .addCase(downloadReceipt.fulfilled, (state) => {
        state.receiptLoading = false;
      })
      .addCase(downloadReceipt.rejected, (state) => {
        state.receiptLoading = false;
      });
  },
});

export const { resetOrderData, setStatus } = orderSlice.actions;
export default orderSlice.reducer;

export const qaStatusSelector = createSelector(
  [(state) => state.order.detail, (state) => state.orderQA.qaStatusList],
  (detail, qaStatusList) => {
    const id = detail?.orderId ?? null;
    if (id) {
      return qaStatusList?.[id] ?? false;
    }
    return false;
  }
);

export const selectedReceiver = () => decodeStorageData('selectedReceiver');
export const selectedStore = () => decodeStorageData('selectedStore');
const detail = (state) => state.order.detail;
const storeList = (state) => state.stores.list;

export const selectedChangedDeliverySelector = createSelector(
  [detail, selectedReceiver, selectedStore, storeList],
  (detail, receiver, store, storeList) => {
    if (detail && Object.keys(detail)?.length) {
      const res = {
        origin: {
          transitTw: detail.delivery.methodId,
          orderNo: detail.orderId,
          deliverId: detail?.delivery.receiverId,
          desireTime: detail?.delivery.deliveryTime,
        },
        receiver: null,
        store: null,
      };
      let originStore = null;
      if (storeList) {
        originStore = storeList.find((store) => {
          return store.storeNo === detail.delivery.store.storeNo;
        });
      }
      if (originStore) {
        const storeJson = {
          convenienceStore: {
            cvstype: originStore.storeGroupId,
            cvsspot: originStore.storeNo,
            name: originStore.storeName,
            addr: originStore.address,
            tel: originStore.phone,
            cvsnum: originStore.routeNo,
            status: originStore.preference ? 1 : 0,
          },
        };
        res.origin.convenienceStore = JSON.stringify(storeJson);
      }

      // 改動宅配地址
      if (Object.keys(receiver)?.length) {
        res.receiver = receiver;
      }
      // 改動超商取貨店家
      if (Object.keys(store)?.length) {
        const storeJson = {
          convenienceStore: {
            cvstype: store.storeGroupId,
            cvsspot: store.storeNo,
            name: store.storeName,
            addr: store.address,
            tel: store.phone,
            cvsnum: store.routeNo,
            status: store.preference ? 1 : 0,
          },
        };
        res.store = JSON.stringify(storeJson);
      }
      return res;
    }
    // 預設
    return {
      origin: null,
      receiver: null,
      store: null,
    };
  }
);
